.online-text{
    font-size: 54px;
}
.online-text1{
    font-size: 50px;
}

.layout2{
    background-image: url('../src/images/online/Section.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.online-content{
    background-image: url('../src/images/page1/Rectangle\ 235.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

@media screen and (min-width:1024px) {
    .online-divs{
        position: absolute;
        top: 94%;
        left: 0;
    }
    .online-img1{
        position: absolute;
        left: -100px;
        top: -50px;
    }
    .online-img2{
        position: absolute;
    }
}

/* OFFLINE */
.offline-div{
    background-image: url('../src/images/online/Hero.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 128vh;
    /* overflow-y: hidden; */
}
.offline-text{
    font-weight: 700;
    font-size: 60px;
    line-height: 54px;
}
.offline-btn{
    height: 50px;
    font-size: 15px;
    letter-spacing: 1px;
}
/* .offline-subdiv{
    overflow-y: hidden;
    height: 82%;
    background-color: aqua;
} */