.work-div{
    background-image: url('../src/images/WORK/Section\ Hero\ Header.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}
.work-div2{
    /* background-image: url('../src/images/WORK/Vector\ 32.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
    height: 20vh;
}
.work-text{
    font-size: 54px;
    font-weight: 900;
    line-height: 60px;
}

.icons{
    color: rgba(70,0,177,1);
color: linear-gradient(180deg, rgba(124,0,217,1) 0%, rgba(70,0,177,1) 57%, rgba(20,23,120,1) 100%);
}

.work-layout4{
    background-image: url('../src/images/WORK/How\ It\ Works.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}