.activity-div{
    background-image: url('../src/images/Group\ 1171276093.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 110vh;
}

.activity-text{
    font-size: 74px;
    text-align: center;
    color: white;
    font-weight: 6  00;
}

.activity-layout4{
    background-image: url('../src/images/Container.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
    border-radius: 25px;
    margin-top: 100px;
}
.activity-layout6{
    background: rgb(49,46,129);
background: linear-gradient(121deg, rgba(49,46,129,1) 0%, rgba(30,64,175,1) 0%, rgba(30,58,138,1) 47%, rgba(112,26,117,1) 100%);
/* min-height: 60vh; */
padding-top: 60px;
padding-bottom: 60px;
align-items: center;
border-radius: 25px;
margin-bottom: 30px;
}

.activity-text2{
    font-size: 60px;
    font-weight: 700;
    color: white;
    text-align: center;
    line-height: 70px;
    padding-bottom: 40px;
}