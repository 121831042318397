.language-div{
    background-image: url('../src/images/online/6.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.language-layout2{
    background-image: url('../src/images/language/About\ Us.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 90vh;
}
.language-text1{
    color: white;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: 2px;
}
.language-text2{
    color: white;
    font-weight: 800;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: 2px;
}

.language-content-div{
    background-image: url('../src/images/online/Stats.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 16vh;
}
/* .slick-center .card {
    transform: scale(1.2);
    transition: transform 0.5s ease;
  } */

  .slick-current .slider-item {
    margin-top: -50px;
    z-index: 10;
    transition: 1s ease-in-out;
    filter: drop-shadow(3px 2px 10px black);
    animation: zoomin 2s linear forwards;
}

@keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.09)
    }

}
  