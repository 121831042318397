.board-div{
    background-image: url('../src/images/Group\ 1171276038.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.board-layout3{
    background-image: url('../src/images/section.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 130vh;
    margin-top: 70px;
}

.board-small-div1{
    width: 280px;
    border-radius: 15px;
  }
  .board-small-div2{
    width: 160px;
    border-radius: 15px;
  }
  .board-small-div3{
    width: 300px;
    border-radius: 15px;
  }

  @media screen and (min-width:1024px){
    .board-small-div1{
        top: 30px;
        left: 30px;
    }
    .board-small-div2{
        top: 100px;
        right: -80px;
    }
    .board-small-div3{
        bottom: -20px;
        left: 40px;
    }
    .board-subdiv{
        width: 50%;
        margin-left: auto;
    }
  }

  .contact{
    background-image: url('../src/images/GradientMesh_Light.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contact-subdiv{
    background-image: url('../src/images/Form\ Content.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contact-cards{
    background-image: url('../src/images/event/address.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
  }
  .contact-cards2{
    background-image: url('../src/images/event/phone.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
  }
  .contact-subtext{
    font-size: 14px;
  }

  .board-subdiv2{
    background-image: url('../src/images/language/Rectangle\ 57.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }