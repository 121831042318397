.event-div{
    background-image: url('../src/images/event/Image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.event-div1{
    background-image: url('../src/images/event/Event.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    margin: auto;
    border-radius: 10px;
    border: 5px solid white;
    padding: 40px;
}

.event-text{
    color: white;
    font-size: 80px;
    font-weight: 800;
    line-height: 80px;
    text-align: center;
    letter-spacing: 3px;
}
.event-text2{
    font-size: 50px;
    text-align: center;
    margin: 0;
    letter-spacing: 2px;
    margin-top: 40px;
}

.event-text3{
    color: #FDD835;
    font-size: 120px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 20px;
}