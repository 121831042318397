.subject-div1{
    background-image: url('../src/images/student/12.\ Personal\ Portfolio.png');
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.layout5-text{
    font-weight: 800;
    color: #8236C8;
}

.packages-div{
    background-color: white;
    border-radius: 15px;
    height: 288px;
    transition: 0.3s ease-in;
}

.packages-div:hover{
background-color: #347EE8;
color: white;
transform: translateY(-5%);
}

.package-icon{
    color: #B2E3FF;
    transition: 0.3s ease-in;
}
.packages-div:hover .package-icon{
    color: #E77C40;
}

.accordion-item {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid lightgray !important;
  }
  
  .accordion-header {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .layout7{
    background-image: url('../src/images/student/Group\ 1171276062.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
  }
  .layout7-btn{
    background: rgb(148,84,254);
background: linear-gradient(259deg, rgba(148,84,254,1) 0%, rgba(77,94,246,1) 50%, rgba(43,204,255,1) 100%);
border-radius: 10px;
height: 51px;
display: flex;
align-items: center;
justify-content: center;
  }

  .cards{
    border-radius: 20px;
    padding: 20px;
    background-image: url('../src/images/student/card-component-active.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .shuffle-card1{
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    height: 290px;
  }
  .shuffle-card2{
    background-color: white;
    border-radius: 10px;
    height: 290px;
    width: 94%;
  }
  .shuffle-card3{
    background-color: white;
    border-radius: 10px;
    height: 290px;
    width: 90%;
  }

  .subject-small-div1{
    width: 160px;
    border-radius: 15px;
  }
  .subject-small-div2{
    width: 160px;
    border-radius: 15px;
  }
  .subject-small-div3{
    width: 240px;
    border-radius: 15px;
  }

  .blog-img{
    height: 359px;
  }

  .navbar1{
    background: rgb(121,6,210);
background: linear-gradient(124deg, rgba(121,6,210,1) 0%, rgba(64,32,210,1) 100%);
  }

  @media screen and (min-width:1024px){
    .subject-small-div1{
        top: 100px;
        left: 30px;
    }
    .subject-small-div2{
        top: 140px;
        right: 0;
    }
    .subject-small-div3{
        bottom: 0px;
        right: 40px;
    }
  }