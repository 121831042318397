*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

/* ADD TUTOR FORM */

.main{
    background-color: #F3F3FD;
}
.inputs{
    background-color: #F4F4F4;
    border: 2px solid #ffff;
}
.labels{
    color: #767676;
    font-weight: 700;
}

.collapse-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.5s ease, opacity 0.5s ease;
  }
  
  .collapse-content.show {
    height: 160px; /* Adjust this height as needed */
    opacity: 1;
  }
  
  /* Homepage */
  .main-div{
    background-image: url('../src/images/Group\ 1171276038.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  /* SIDEBAR */
  .sidebar{
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 6;
}
  .sidebar-valid{
    color: white;
    background: transparent;
  }
  .sidebar-valid2{
    color: gray;
    background:white;
    border: none;
    border-radius: 15px;
  }
  .color1{
    color: #5B5B5B;
  }

  .main-container{
    margin-left: 220px;
    min-height: 100vh;
    background-color: #F3F3FD;
    padding: 30px;
  }

  /* Filter */
  .filter-input{

  }
  .filter-input::placeholder{
    color: #9e9e9e;
  }
  .filter-label{
    color: #9E9E9E;
  }
  .btns{
    color: #4787E3;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }

  /* Landing pages */
  .main-div{
    background-image: url('../src/images/page1/Group\ 1171276045.png');
    height: 140vh ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   }
   .main-div1{
    height: 65%;
   }

   .main-text{
    font-family: "Bebas Neue", sans-serif;
   }

   .main-btn{
    background: rgb(81,192,249);
background: linear-gradient(167deg, rgba(81,192,249,1) 0%, rgba(69,121,247,1) 100%);
color: white;
font-size: 14px;
height: 54px;
   }

   .nav1{
    color: white;
    font-size: 16px;
    cursor: pointer;
   }

   .main-div2{
    background-color: #fcf9f8;
    height: 110vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   }

   .about-us{
    border-radius: 10px;
    height: 64px;
    background-color: white;
    display: flex;
    align-items: center;
    position: relative;
   }


   .about-us2{
    background-image: url('../src/images/page1/1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
    padding: 30px;
   }

   .rewards{
    background: rgb(231,240,255);
background: linear-gradient(173deg, rgba(231,240,255,1) 0%, rgba(240,240,255,1) 57%, rgba(255,255,255,1) 100%);
padding: 30px;
border-radius: 14px 14px 0px 0px;
margin-top: 10px;
position: relative;
   }

   .rewards p{
    font-size: 14px;
   }

   .rewards-span{
    display: block;
    color: #742BFA;
    font-weight: 600;
   }

   .reviews-div{
      background-image: url('../src/images/page1/Rectangle\ 68.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 30px;
      border-radius: 10px;
   }

   .review{
    background-image: url('../src/images/page1/Rectangle\ 67.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
 }

 .select-btn{
  color: white;
  background: transparent;
 }
 .selected-btn{
  color: #003FE0;
  background-color: white;
 }

 .lang-div{
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  height: 320px;
  transition: 0.7s ease-out;
 }
 .lang-div:hover{
  background: rgb(124,0,217);
background: linear-gradient(180deg, rgba(124,0,217,1) 0%, rgba(93,0,194,1) 57%, rgba(20,23,120,1) 100%);
color: white;
 }

 .review-subdiv{
  background: rgb(0,0,0);
background: linear-gradient(173deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.11817226890756305) 100%);
 }
 .review-subdiv2{
  background: rgb(0,0,0);
background: linear-gradient(173deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.11817226890756305) 100%);
 }

 .footer-input::placeholder{
  color: gray;
 }
 .footer-list{
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
 }

 .back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: rgb(231,240,255);
background: linear-gradient(173deg, rgba(231,240,255,1) 0%, rgba(240,240,255,1) 57%, rgba(255,255,255,1) 100%);
color: gray;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-to-top-btn.show {
  opacity: 1;
}

.back-to-top-btn.hide {
  opacity: 0;
}

.container1{
  /* padding-top: 82px; */
}

@media screen and (min-width:1024px) {
  .main-text{
    font-size: 104px; 
  }
}

@media screen and (max-width:767px) {
  .main-text{
    font-size: 70px; 
  }
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .main-text{
    font-size: 104px; 
  }
}

.main-form{
  background-image: url('../src/images/form-book-primary.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  border-radius: 10px;
  margin: auto;
}
.how-it-works-form{
  background-image: url('../src/images/form-book-primary.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.navbar-drop{
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(10px); /* Add background blur */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  transition: 0.5s;
}
.nav-li{
  background: transparent;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
}

.login-div{
  background-image: url('../src/images/Group\ 1171276003.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-tooltip .tooltip-inner{
  background-color: #ffff;
  color: black;
  border: 1px solid gray;
}